@use '/src/assets/variables.scss' as *;
.popup-module.banner-popup {
  & .modal-dialog {
    width: auto !important;
    height: auto !important;
    .modal-content {
      border-radius: 0;
      .modal-header {
        margin-top: -54px;
        margin-bottom: 0px;
        padding-bottom: 8px;
        padding-right: 8px;
        .btn-close {
          background-color: #ffffff;
          opacity: 1;
          border-radius: 4px 4px 0 0;
          width: 40px;
          height: 30px;

          &:hover {
            opacity: 0.7;
          }
        }
      }
      .modal-body {
        padding: 0;
        & .slick-slider {
          & a.row {
            color: $color-body;
          }
          &.slick-initialized {
            position: unset;
          }
          .slick-arrow {
            position: absolute;
            z-index: 30;
            top: 50%;
            width: 95px;
            height: 44px;
            border-radius: 22px;
            border: 0;
            padding: 0;
            margin: -22px 0 0;
            overflow: hidden;
            font-size: 0;
            line-height: 0;
            font-family: $font-secondary5;
            cursor: pointer;
            color: #ffffff;
            -webkit-transition: all 0.6s;
            transition: all 0.6s;

            @media screen and (max-width: 426px),
              screen and (orientation: landscape) and (max-width: 426px),
              screen and (orientation: portrait) and (max-width: 426px) {
              width: 65px;
              height: 30px;
              border-radius: 16px;
            }

            &::before {
              position: absolute;
              z-index: 20;
              top: 0;
              margin: 7px 0 0;
              font-size: 36px;
              opacity: 1;

              @media screen and (max-width: 426px),
                screen and (orientation: landscape) and (max-width: 426px),
                screen and (orientation: portrait) and (max-width: 426px) {
                font-size: 25px;
                line-height: 27px;
                margin: 3px 0 0;
              }
            }

            &:hover {
              border-color: $color-mobile-light;
              color: #ffffff;
            }

            &.slick-prev {
              left: -20px;
              border-color: $color-primary;
              background: rgb(55, 72, 159);
              background: -moz-linear-gradient(
                left,
                rgba(55, 72, 159, 1) 0%,
                rgba(55, 72, 159, 1) 33%,
                rgba(33, 43, 96, 1) 66%,
                rgba(33, 43, 96, 1) 100%
              );
              background: -webkit-linear-gradient(
                left,
                rgba(55, 72, 159, 1) 0%,
                rgba(55, 72, 159, 1) 33%,
                rgba(33, 43, 96, 1) 66%,
                rgba(33, 43, 96, 1) 100%
              );
              background: linear-gradient(
                to right,
                rgba(55, 72, 159, 1) 0%,
                rgba(55, 72, 159, 1) 33%,
                rgba(33, 43, 96, 1) 66%,
                rgba(33, 43, 96, 1) 100%
              );
              background-size: 300% 100%;
              background-position: 100% 0;

              @media screen and (max-width: 426px),
                screen and (orientation: landscape) and (max-width: 426px),
                screen and (orientation: portrait) and (max-width: 426px) {
                left: -12px;
              }

              &::before {
                left: 10px;
                @media screen and (max-width: 426px),
                  screen and (orientation: landscape) and (max-width: 426px),
                  screen and (orientation: portrait) and (max-width: 426px) {
                  left: 6px;
                }
              }

              &:hover {
                background-position: 0 0;
              }
            }

            &.slick-next {
              right: -20px;
              border-color: $color-primary;
              background: rgb(33, 43, 96);
              background: -moz-linear-gradient(
                left,
                rgba(33, 43, 96, 1) 0%,
                rgba(33, 43, 96, 1) 33%,
                rgba(55, 72, 159, 1) 66%,
                rgba(55, 72, 159, 1) 100%
              );
              background: -webkit-linear-gradient(
                left,
                rgba(33, 43, 96, 1) 0%,
                rgba(33, 43, 96, 1) 33%,
                rgba(55, 72, 159, 1) 66%,
                rgba(55, 72, 159, 1) 100%
              );
              background: linear-gradient(
                to right,
                rgba(33, 43, 96, 1) 0%,
                rgba(33, 43, 96, 1) 33%,
                rgba(55, 72, 159, 1) 66%,
                rgba(55, 72, 159, 1) 100%
              );
              background-size: 300% 100%;

              @media screen and (max-width: 426px),
                screen and (orientation: landscape) and (max-width: 426px),
                screen and (orientation: portrait) and (max-width: 426px) {
                right: -12px;
              }

              &::before {
                right: 10px;
                @media screen and (max-width: 426px),
                  screen and (orientation: landscape) and (max-width: 426px),
                  screen and (orientation: portrait) and (max-width: 426px) {
                  right: 6px;
                }
              }

              &:hover {
                background-position: 100% 0;
              }
            }
          }

          .slick-dots {
            background: $color-white;
            min-height: 40px;
          }

          .slick-list {
            height: auto;

            & .slick-track {
              overflow-x: hidden;
              height: inherit;
            }

            .slick-slide {
              > div:first-child {
                height: inherit;
              }
              .img-popup-container {
                display: flex !important;
                height: inherit;
                width: 100%;
                .text-container {
                  padding: 20px;
                }
                .img-container {
                  img {
                    width: -webkit-fill-available;
                    height: 100%;
                    object-fit: cover;
                    width: 100%;
                  }
                }
              }

              & img {
                width: 100%;
              }

              & .banner img {
                vertical-align: top;
                max-width: 100%;
                height: auto;
                object-fit: cover;
              }

              & .banner.img-container,
              & .popup.text-container,
              & .img-popup-container,
              & .popup-iframe {
                overflow: auto;
              }

              & .banner.img-container {
                margin: 0;
                height: 100%;
                padding: 0;
                background-repeat: no-repeat;
                background-size: 100%;
                background-position: 50% 50%;
                background-size: cover;
                display: inherit !important;
              }
              & .row.img-popup-container .img-container {
                padding-left: 0;
                padding-right: 0;
              }
              & .row {
                --bs-gutter-x: 0rem;
              }
            }
          }

          & .slick-dots {
            background: transparent;
            margin-top: -60px;
            bottom: inherit;
            @media screen and (max-width: 991px),
              screen and (orientation: landscape) and (max-width: 991px),
              screen and (orientation: portrait) and (max-width: 991px) {
              bottom: -107px;
            }
            @media screen and (max-width: 425px),
              screen and (orientation: landscape) and (max-width: 425px),
              screen and (orientation: portrait) and (max-width: 425px) {
              bottom: -20px;
            }
          }
        }
      }
      & .modal-footer > * {
        margin: 0;
      }
      & .modal-footer {
        padding: 0;
        margin-bottom: 0;
        min-height: 90px;
        @media screen and (max-width: 425px),
          screen and (orientation: landscape) and (max-width: 425px),
          screen and (orientation: portrait) and (max-width: 425px) {
          margin-top: 20px;
        }

        & .action-box {
          width: 100%;
          & [class*='col-'] {
            display: flex;
            align-items: center;
            justify-content: center;
          }

          & .btn {
            width: 200px;
            min-width: 200px;
          }
          & .btn-primary {
            color: $color-white;
          }

          & .lbl {
            font-size: 14px;
            font-weight: 400;
          }
          & .checkbox .lbl:before {
            top: 3px;
            left: -3px;
          }
          & .checkbox .lbl:after {
            top: 5px;
            left: -1px;
          }
        }
      }
    }
  }
}

.popup-module .modal-dialog .modal-content .modal-body .slick-slider .slick-list .slick-slide .row {
  overflow-y: hidden;
}
